<template>
  <div class="mx-4 my-4 md:mx-8">
    <Breadcrumb :links="breadcrumb" />
    <br />
    <h1 class="text-3xl mb-5">Faturamento</h1>
    <form class="mb-2">
      <div class="grid md:grid-cols-6 gap-3">
        <div>
          <label for="de" class="mt-2 block text-sm font-medium">A partir de</label>
          <input
            v-model="de"
            type="date"
            name="de"
            id="de"
            class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label for="ate" class="mt-2 block text-sm font-medium">Até</label>
          <input
            v-model="ate"
            type="date"
            name="ate"
            id="ate"
            class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label for="ate" class="mt-2 block text-sm font-medium">Centro de custo</label>
          <v-select v-model="centrocusto" :options="centroscusto" label="nome" :reduce="(c) => c._id" name="centrocusto" id="centrocusto" class="mt-1"></v-select>
        </div>
        <div>
          <button
            @click="start"
            type="button"
            class="mt-9 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-md text-sm shadow-sm hover:shadow-md w-full font-semibold text-center"
          >
            <span class="inline-block mr-2">Gerar relátorio</span>
          </button>
        </div>
        <div>
          <label for="email" class="mt-2 block text-sm font-medium">Email download relatório</label>
          <input
            v-model="email"
            type="email"
            name="email"
            id="email"
            class="mt-1 h-10 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
        </div>
        <div>
          <button
            @click="downloadRelatorio"
            type="button"
            class="mt-9 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-md text-sm shadow-sm hover:shadow-md w-full font-semibold text-center"
          >
            <span class="inline-block mr-2">Download relátorio</span>
          </button>
        </div>
      </div>
    </form>
    <p v-if="progresso !== 100" class="text-gray-800 font-semibold">Progressão: <span class="text-green-500">{{ progresso }}%</span> / 100%</p>
    <p v-else class="text-gray-800 font-semibold text-sm">Relatório gerado com sucesso</p>
    <div class="grid grid-cols-12 gap-6 mt-6">
      <div class="col-span-12 md:col-span-3">
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg px-4 py-5"
        >
          <label class="block font-bold text-lg text-gray-800 uppercase mb-1"
            >Campanhas envio massa
          </label>
          <label class="block text-black text-base font-semibold"
            >
            <b>{{ this.faturamento.totalMassa }}</b> <span v-if="$store.state.user.lzv2.faturamento.visivel">x {{numberFormat($store.state.user.lzv2.faturamento.envio)}} =
              <small class="text-green-500 text-lg">{{
                formatPrice(this.faturamento.valorMassa)
              }}</small></span>
           
          </label>
        </div>
      </div>
      <div class="col-span-12 md:col-span-3">
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg px-4 py-5"
        >
          <label class="block font-bold text-lg text-gray-800 uppercase mb-1"
            >Conversas ativas
          </label>
          <label class="block text-black text-base font-semibold"
            >
            <b>{{ this.faturamento.totalChats }}</b> <span v-if="$store.state.user.lzv2.faturamento.visivel">x {{numberFormat($store.state.user.lzv2.faturamento.chat)}} =
              <small class="text-green-500 text-lg">{{
                formatPrice(this.faturamento.valorChats)
              }}</small></span>
            
          </label>
        </div>
      </div>
      <div class="col-span-12 md:col-span-3" v-if="!$store.state.user.lzv2.faturamento.visivel || $store.state.user.lzv2.faturamento.visivel && $store.state.user.lzv2.faturamento.operador > 1">
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg px-4 py-5"
        >
          <label class="block font-bold text-lg text-gray-800 uppercase mb-1"
            >Operadores ativos
          </label>
          <label class="block text-black text-base font-semibold"
            >
            <b>{{ this.faturamento.totalOperadores }}</b> <span v-if="$store.state.user.lzv2.faturamento.visivel">x {{numberFormat($store.state.user.lzv2.faturamento.operador)}} =
            <small class="text-green-500 text-lg">{{
              formatPrice(this.faturamento.valorOperadores)
            }}</small></span>
          </label>
        </div>
      </div>
      <div class="col-span-12 md:col-span-3">
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg px-4 py-5"
        >
          <label class="block font-bold text-lg text-gray-800 uppercase mb-1"
            >Validação Whatsapp
          </label>
          <label class="block text-black text-base font-semibold"
            >
            <b>{{ this.faturamento.totalValidacao }}</b> <span v-if="$store.state.user.lzv2.faturamento.visivel">x R$ 0,01 =
            <small class="text-green-500 text-lg">{{
              formatPrice(this.faturamento.valorValidacao)
            }}</small></span>
          </label>
        </div>
      </div>
      <div class="col-span-12">
        <p v-if="$store.state.user.lzv2.faturamento.visivel" class="text-lg font-semibold text-gray-700">
          Total:
          <span class="text-green-700">{{ formatPrice(this.faturamento.total) }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Breadcrumb from "../../components/Breadcrumbs.vue";

export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      breadcrumb: [{ url: "/relatorios/faturamento", value: "Faturamento" }],
      centroscusto: [],
      route: "auditoria",
      de: moment().startOf("month").format("YYYY-MM-DD"),
      ate: moment().endOf("month").format("YYYY-MM-DD"),
      centrocusto: null,
      email: "",
      progresso: 0,
      faturamento: {
        totalMassa: 0,
        totalChats: 0,
        totalOperadores: 0,
        totalValidacao: 0,
        valorMassa: 0,
        valorChats: 0,
        valorOperadores: 0,
        valorValidacao: 0,
        total: 0,
      },
    };
  },
  mounted() {
    this.sockets.subscribe(`progressaoFaturamento_${this.$store.state.user._id}`,
      (progresso) => {
        this.progresso = progresso;
      });
  },
  methods: {
    async start() {
      const faturamentoReq = await this.$http.post("/v1/campanhasLZV2/faturamento", {
        de: this.de,
        ate: this.ate,
        centrocusto: this.centrocusto,
      });
      this.faturamento = faturamentoReq.data;
    },
    async downloadRelatorio() {
      const faturamentoReq = await this.$http.post("/v1/campanhasLZV2/faturamento/dowload", {
        de: this.de,
        ate: this.ate,
        email: this.email,
        centrocusto: this.centrocusto,
      });
      if (faturamentoReq.data.success) {
        this.$vToastify.success(faturamentoReq.data.msg);
        this.email = "";
      } else {
        this.$vToastify.error(faturamentoReq.data.err);
      }
    },
    numberFormat (value) {
      return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
  },
  async beforeMount() {
    const centroscustoReq = await this.$http.post(`/v1/centrocusto/list`, { all: true });
    this.centroscusto = centroscustoReq.data.data;

    if (this.$store.state.user.centrocusto) {
      this.centrocusto = this.$store.state.user.centrocusto;
    }

    await this.start();
  },
};
</script>
